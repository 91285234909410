/* eslint-disable react/no-unused-prop-types */
import DownloadIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/styles';
import React, {
  cloneElement,
  KeyboardEventHandler,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Button,
  Datagrid,
  EditButton,
  EditButtonProps,
  Filter,
  List,
  ListContextProvider,
  ListProps,
  ListView,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useListController,
  useNotify,
  usePermissions,
} from 'react-admin';
import styled from 'styled-components';
import { Storage, User } from 'ultimate-league-common';
import { AthleteBulkEditButton } from '~business/athlete/AthleteBulkEditButton';
import { buildQuery, fetchApi } from '~technical/api';
import { getQuery } from '~technical/dataProvider';
import { AthleteField } from '~technical/filters/athlete';
import { error } from '~technical/logger';
import { AssetField } from '~ui/AssetField';
import { QuickFilter } from '~ui/QuickFilter';

import { SPORT_CONTEXT } from '../../constant';
import { tierChoices } from './common';

const BulkButtons = styled.div`
  & > button {
    margin: 0 10px;
  }
`;

const StyledTierFilter = styled(SelectArrayInput)`
  width: 100px;
`;

const AthleteFilter = ({ children, ...props }: PropsWithChildren<{}>) => {
  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    /* TODO: fix types */
    /* @ts-ignore */
    <Filter {...props} onKeyPress={handleKeyPress}>
      <TextInput source={AthleteField.JOKER} label="Athlete" />
      <ReferenceInput
        label="Team"
        source="team"
        reference="team"
        filterToQuery={(search: string) => ({ name: search })}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <StyledTierFilter label="Tier" source="tier" choices={tierChoices} />
      {children}
    </Filter>
  );
};

const useListViewStyle = makeStyles({
  bulkActionsDisplayed: {
    marginTop: 0,
  },
});

const useDatagridStyle = makeStyles({
  headerCell: {
    '& input[type="checkbox"] ~ svg': {
      display: 'none',
    },
  },
});

interface IParams extends Omit<ListProps, 'resource' | 'basePath'> {
  onToggleItem: (toggleId: string) => void;
  selectedIds: string[];
}

export const useAthletesListController = ({
  onToggleItem,
  selectedIds,
  ...params
}: IParams) => {
  const controller = useListController({
    resource: 'athlete',
    basePath: '/athletes',
    ...params,
  });

  return useMemo(
    () => ({
      ...controller,
      onToggleItem,
      selectedIds,
    }),
    [controller, onToggleItem, selectedIds]
  );
};

interface IProps {
  contextValue: ReturnType<typeof useAthletesListController>;
}

export const AthleteListInContext = ({ contextValue }: IProps) => (
  <ListContextProvider value={contextValue}>
    <ListView
      resource="athlete"
      basePath="/athletes"
      filters={<AthleteFilter />}
      // @ts-ignore bulkActionButtons=null will hide actions but allow to select rows.
      bulkActionButtons={null}
      classes={useListViewStyle()}
    >
      <Datagrid classes={useDatagridStyle()} hasBulkActions={false}>
        <TextField source="matchName" />
        <ReferenceField source="club" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="national" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </ListView>
  </ListContextProvider>
);

const Actions = (props: EditButtonProps) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <EditButton {...props} />
  </div>
);

const BulkActionButtons = () => (
  <BulkButtons>
    <AthleteBulkEditButton />
  </BulkButtons>
);

const ExportButton = () => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const { filterValues } = useListContext();

  const exportAthletes = useCallback(async () => {
    setLoading(true);
    try {
      const query = getQuery('athlete', {
        sport: SPORT_CONTEXT,
        ...(filterValues ?? {}),
      });
      await fetchApi(`/backoffice/athlete-export?${buildQuery(query)}`, {
        method: 'POST',
      });
      notify(
        'Athletes export will be emailed to you within a few minutes',
        'info'
      );
    } catch (e) {
      if (e instanceof Error) {
        error(e);
      }
      notify('An unexpected error occurred while exporting athletes', 'error');
    }
    setLoading(false);
  }, [filterValues, setLoading, notify]);

  return (
    <Button label="Export" disabled={loading} onClick={exportAthletes}>
      <DownloadIcon />
    </Button>
  );
};

const ListActions = ({ filters }: any) => (
  <TopToolbar>
    {cloneElement(filters, { context: 'button' })}
    <ExportButton />
  </TopToolbar>
);

export const AthleteList = (props: {}) => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      actions={<ListActions />}
      exporter={false}
      filters={
        <AthleteFilter>
          <QuickFilter
            source={AthleteField.MISSING_DATA}
            label="Requires manual data"
            defaultValue
          />
          <QuickFilter
            source={AthleteField.MINTED}
            label="Minted"
            defaultValue
          />
        </AthleteFilter>
      }
      bulkActionButtons={
        permissions === User.Role.MODERATOR ? <BulkActionButtons /> : false
      }
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
      perPage={25}
    >
      <Datagrid>
        <TextField source="matchName" />

        <NumberField source="tier" />

        <ReferenceField source="club" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <NumberField source="shirtNumber" />

        <ReferenceField source="national" reference="team" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          source="country"
          reference="country"
          link={permissions === User.Role.MODERATOR}
        >
          <TextField source="name" />
        </ReferenceField>

        <AssetField
          assetParams={{
            storageType: Storage.StorageType.PUBLIC_ATHLETE_MAIN,
            format: Storage.AssetFormat.SMALL,
          }}
          source="assets.main"
          label="Main asset"
        />

        <BooleanField source="ignored" />

        <Actions />
      </Datagrid>
    </List>
  );
};
